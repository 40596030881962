// src/components/Header.js
import React, { useEffect, useState, useRef } from 'react';
import { getUserDetails } from '../api/api';

const Header = () => {
  const [userName, setUserName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState(null);
  const fetchCalled = useRef(false); // Prevent duplicate fetch calls

  useEffect(() => {
    if (fetchCalled.current) return; // If already called, skip
    fetchCalled.current = true; // Mark as called

    const fetchUserName = async () => {
      try {
        const userDetails = await getUserDetails();
        setUserName(`${userDetails.first_name} ${userDetails.last_name}`);
        setCompanyName(`${userDetails.company.name}`);
      } catch (err) {
        setError('Failed to load user details');
        console.error(err);
      }
    };

    fetchUserName();
  }, []);

  return (
    <nav
      className="navbar navbar-expand-lg navbar-light bg-light border-bottom"
      style={{ position: 'sticky', top: 0, zIndex: 1020 }}
    >
      <div className="container-fluid">
        <h5 className="mb-0">{companyName}</h5>
        <div className="d-flex align-items-center">
          {error ? (
            <span className="text-danger me-3">{error}</span>
          ) : (
            <span className="me-3">{userName}</span>
          )}
          <a href="/logout" className="btn btn-sm btn-outline-danger">
            Logout
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Header;
