// src/api/api.js
const BASE_URL = 'https://justqrmenu-9b224502ea73.herokuapp.com';

const getToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to login page if there's no token
      window.location.href = '/login';
      return null;
    }
    return token;
  };

// Function to create headers
const createHeaders = (includeAuth = false) => {
  const headers = {
    'Content-Type': 'application/json',
    'Accept-Language': 'tr',
  };
  if (includeAuth) {
    const token = getToken();
    if (token) headers['Authorization'] = `Token ${token}`;
  }
  return headers;
};

// Login function
export const login = async (username, password) => {
  const response = await fetch(`${BASE_URL}/api/v1/login/`, {
    method: 'POST',
    headers: createHeaders(),
    body: JSON.stringify({ username, password }),
  });
  if (!response.ok) throw new Error('Login failed');
  return response.json();
};

// Logout function
export const logout = async () => {
  const response = await fetch(`${BASE_URL}/api/v1/logout/`, {
    method: 'POST',
    headers: createHeaders(true),
  });
  if (!response.ok) {
    console.log(response);
    

    throw new Error('Logout failed');
  } 
  return response.json();
};

// Fetch user details
export const getUserDetails = async () => {
  const response = await fetch(`${BASE_URL}/api/v1/me/`, {
    method: 'GET',
    headers: createHeaders(true),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch user details');
  }
  return response.json();
};

export const getProducts = async (ordering = '-pk') => {
  const response = await fetch(`${BASE_URL}/company-api/v1/products/?ordering=${ordering}`, {
    method: 'GET',
    headers: createHeaders(true),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch products');
  }
  return response.json();
};

export const getIngredients = async (limit = 1000, page = 1, ordering = '-pk') => {
  const response = await fetch(`${BASE_URL}/company-api/v1/ingredients/?limit=${limit}&page=${page}&ordering=${ordering}`, {
    method: 'GET',
    headers: createHeaders(true),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch ingredients');
  }
  return response.json();
};

export const createIngredient = async (ingredientData) => {
  const response = await fetch(`${BASE_URL}/company-api/v1/ingredients/`, {
    method: 'POST',
    headers: createHeaders(true),
    body: JSON.stringify(ingredientData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Failed to create ingredient');
  }

  return response.json();
};

// Delete ingredient
export const deleteIngredient = async (id) => {
  const response = await fetch(`${BASE_URL}/company-api/v1/ingredients/${id}/`, {
    method: 'DELETE',
    headers: createHeaders(true),
  });

  if (!response.ok) {
    throw new Error('Failed to delete ingredient');
  }
};

// Update ingredient
export const updateIngredient = async (id, updatedData) => {
  const response = await fetch(`${BASE_URL}/company-api/v1/ingredients/${id}/`, {
    method: 'PUT',
    headers: createHeaders(true),
    body: JSON.stringify(updatedData),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Failed to update ingredient');
  }

  return response.json();
};

