import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom'; // React Router for query parameters
import { getProducts } from '../api/api'; // API function for products

const Products = () => {
  const [products, setProducts] = useState([]);
  const [error, setError] = useState(null);
  const [totalCount, setTotalCount] = useState(0); // Total items count
  const [searchParams, setSearchParams] = useSearchParams(); // Query parameters

  // Get initial values from URL or set defaults
  const page = parseInt(searchParams.get('page')) || 1; // Default page = 1
  const limit = parseInt(searchParams.get('limit')) || 20; // Default limit = 20

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getProducts(limit, page);
        setProducts(data.results);
        setTotalCount(data.count);
      } catch (err) {
        setError('Failed to load products');
        console.error(err);
      }
    };

    fetchProducts();
  }, [page, limit]); // Re-fetch when page or limit changes

  const totalPages = Math.ceil(totalCount / limit);

  // Update query parameters
  const updateQueryParams = (newPage, newLimit) => {
    setSearchParams({ page: newPage, limit: newLimit });
  };

  return (
    <div className="container py-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1>Products</h1>
        <button className="btn btn-primary">Create Product</button>
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      {/* Limit Dropdown */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <label htmlFor="limit" className="form-label me-2">
            Items per page:
          </label>
          <select
            id="limit"
            className="form-select d-inline-block w-auto"
            value={limit}
            onChange={(e) => {
              const newLimit = parseInt(e.target.value, 10);
              updateQueryParams(1, newLimit); // Reset to page 1 when limit changes
            }}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <span>Total Items: {totalCount}</span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Price</th>
              <th>Created At</th>
              <th>Updated At</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td>{product.id}</td>
                <td>{product.name}</td>
                <td>{product.description}</td>
                <td>${product.price}</td>
                <td>{new Date(product.created_at).toLocaleString()}</td>
                <td>{new Date(product.updated_at).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          disabled={page === 1}
          onClick={() => updateQueryParams(page - 1, limit)}
        >
          Previous
        </button>
        <span>
          Page {page} of {totalPages}
        </span>
        <button
          className="btn btn-secondary"
          disabled={page === totalPages}
          onClick={() => updateQueryParams(page + 1, limit)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Products;
