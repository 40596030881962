import React from 'react';

const Dashboard = () => {
  return (
    <div className="container-fluid">
      <div className="row">
        {/* Page Heading */}
        <div className="col-12 mb-4">
          <h2>Dashboard</h2>
          <p>Welcome to the admin panel. Select an option from the menu.</p>
        </div>

        {/* Example Cards Section */}
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Products</h5>
              <p className="card-text">Manage your products and inventory.</p>
              <a href="/products" className="btn btn-primary">Go to Products</a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Categories</h5>
              <p className="card-text">Organize your product categories.</p>
              <a href="/categories" className="btn btn-primary">Go to Categories</a>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6 col-lg-4 mb-4">
          <div className="card shadow-sm">
            <div className="card-body">
              <h5 className="card-title">Orders</h5>
              <p className="card-text">Track and manage orders from customers.</p>
              <a href="/orders" className="btn btn-primary">Go to Orders</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
