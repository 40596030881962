import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getIngredients, createIngredient, updateIngredient, deleteIngredient } from '../api/api';

const Ingredients = () => {
  const [ingredients, setIngredients] = useState([]);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [newIngredientName, setNewIngredientName] = useState('');
  const [editingIngredient, setEditingIngredient] = useState(null);
  const [editedName, setEditedName] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();

  const page = parseInt(searchParams.get('page')) || 1;
  const limit = parseInt(searchParams.get('limit')) || 20;
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    const fetchIngredients = async () => {
      try {
        const data = await getIngredients(limit, page);
        setIngredients(data.results);
        setTotalCount(data.count);
      } catch (err) {
        setError('Failed to load ingredients');
        console.error(err);
      }
    };

    fetchIngredients();
  }, [page, limit]);

  useEffect(() => {
    if (successMessage || error) {
      const timer = setTimeout(() => {
        setSuccessMessage(null);
        setError(null);
      }, 3000); // Clear alert after 3 seconds
      return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [successMessage, error]);

  const totalPages = Math.ceil(totalCount / limit);

  const updateQueryParams = (newPage, newLimit) => {
    setSearchParams({ page: newPage, limit: newLimit });
  };

  const handleCreateIngredient = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!newIngredientName.trim()) {
      setError('Ingredient name cannot be empty.');
      return;
    }

    try {
      const newIngredient = await createIngredient({ name: newIngredientName });
      setIngredients((prev) => [newIngredient, ...prev]);
      setSuccessMessage(`Ingredient "${newIngredient.name}" created successfully!`);
      setNewIngredientName('');
    } catch (err) {
      setError(err.message || 'Failed to create ingredient');
    }
  };

  const handleEditIngredient = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccessMessage(null);

    if (!editedName.trim()) {
      setError('Ingredient name cannot be empty.');
      return;
    }

    try {
      const updatedIngredient = await updateIngredient(editingIngredient.id, { name: editedName });
      setIngredients((prev) =>
        prev.map((ingredient) =>
          ingredient.id === updatedIngredient.id ? updatedIngredient : ingredient
        )
      );
      setSuccessMessage(`Ingredient updated to "${updatedIngredient.name}" successfully!`);
      setEditingIngredient(null);
      setEditedName('');
    } catch (err) {
      setError('Failed to update ingredient');
    }
  };

  const handleDeleteIngredient = async (id) => {
    try {
      await deleteIngredient(id);
      setIngredients((prev) => prev.filter((ingredient) => ingredient.id !== id));
      setSuccessMessage('Ingredient deleted successfully!');
    } catch (err) {
      setError('Failed to delete ingredient');
    }
  };

  return (
    <div className="container py-4">
      <h1 className="mb-4">Ingredients</h1>

      {error && <div className="alert alert-danger">{error}</div>}
      {successMessage && <div className="alert alert-success">{successMessage}</div>}

      <form onSubmit={handleCreateIngredient} className="d-flex mb-4">
        <input
          type="text"
          className="form-control me-2"
          placeholder="Enter new ingredient name"
          value={newIngredientName}
          onChange={(e) => setNewIngredientName(e.target.value)}
        />
        <button type="submit" className="btn btn-primary">
          Add Ingredient
        </button>
      </form>

      <div className="d-flex justify-content-between align-items-center mb-3">
        <div>
          <label htmlFor="limit" className="form-label me-2">
            Items per page:
          </label>
          <select
            id="limit"
            className="form-select d-inline-block w-auto"
            value={limit}
            onChange={(e) => {
              const newLimit = parseInt(e.target.value, 10);
              updateQueryParams(1, newLimit);
            }}
          >
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
        <div>
          <span>Total Items: {totalCount}</span>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-dark">
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {ingredients.map((ingredient) => (
              <tr key={ingredient.id}>
                <td>{ingredient.id}</td>
                <td>
                  {editingIngredient?.id === ingredient.id ? (
                    <form onSubmit={handleEditIngredient} className="d-flex">
                      <input
                        type="text"
                        className="form-control form-control-sm me-2"
                        value={editedName}
                        onChange={(e) => setEditedName(e.target.value)}
                      />
                      <button type="submit" className="btn btn-success btn-sm me-1">
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        onClick={() => setEditingIngredient(null)}
                      >
                        Cancel
                      </button>
                    </form>
                  ) : (
                    ingredient.name
                  )}
                </td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-secondary me-2"
                    onClick={() => {
                      setEditingIngredient(ingredient);
                      setEditedName(ingredient.name);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => handleDeleteIngredient(ingredient.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-secondary"
          disabled={page === 1}
          onClick={() => updateQueryParams(page - 1, limit)}
        >
          Previous
        </button>
        <span>
          Page {page} of {totalPages}
        </span>
        <button
          className="btn btn-secondary"
          disabled={page === totalPages}
          onClick={() => updateQueryParams(page + 1, limit)}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Ingredients;
