// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './AuthContext';
import PrivateRoute from './PrivateRoute';
import Layout from './components/Layout';

import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Logout from './pages/Logout';
import NotFound from './pages/NotFound';
import Ingredients from './pages/Ingredients';
import Products from './pages/Products';
//import Products from './pages/Products'; // Example additional page

const routesWithLayout = [
  { path: '/', element: <Dashboard /> },
  { path: '/products', element: <Products /> },
  { path: '/ingredients', element: <Ingredients /> },
  // Add more pages here
];

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Routes without layout */}
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />

          {/* Routes with layout */}
          {routesWithLayout.map(({ path, element }, index) => (
            <Route
              key={index}
              path={path}
              element={<PrivateRoute><Layout>{element}</Layout></PrivateRoute>}
            />
          ))}

          {/* 404 Route */}
          <Route path="*" element={<PrivateRoute><NotFound /></PrivateRoute>} />

        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
